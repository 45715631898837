<template>
  <div id="banner" v-show="isBannerActive" :class="color == 'teal' ? 'teal-banner' : 'blue-banner'">
    <p>{{text}}</p>
    <svg class="close" @click="this.closeBanner()" width="15" height="15" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L73 73M73 1L1 73" stroke="#2E4F9C" stroke-width="8"/>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'Banner',
  props: ['text', 'color'],
  data() {
    return {
    }
  },
  methods: {
    closeBanner() {
      this.$emit('updateBannerState', false);
      this.$store.commit('updateBannerState', false);
    }
  },
  computed: {
    isBannerActive() {
      return this.$store.getters.getBannerState;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables.scss';
#banner {
  width: 100%;
  position: fixed;
  z-index: 999;
  margin-bottom: -1em;

  p {
    width: 90%;
    margin: auto;
    text-align: center;
    font-size: 1em;
    padding-top: .5em;
  }

  svg {
    position: absolute;
    right: .5em;
    top:.5em;
    
    &:hover {
      cursor: pointer;
    }
  }

  &.teal-banner {
    background-color: $teal;
    color: $blue;
    path {
      stroke: $blue;
    }
  }

  &.blue-banner {
    background-color: $blue;
    color: $teal;
    path {
      stroke: $teal;
    }
  }
}


</style>