<template>
  <div class="hidden-lp">
      <div id="landing-page-nav">
        <router-link :to="{ path: '/home'}">Back to Red + Blue</router-link>
      </div>
      <section id="hidden-lp-header">
        <img id="header-1" src="@/assets/img/landing-pages/hidden/h_header-1.png">
        <div id="hidden-logo" class="centered mg-auto">
          <img src="@/assets/img/landing-pages/hidden/h_logo.png">
          <h1 class="hidden-red">Discover the magical world hidden in postcards!</h1>
        </div>
        <img id="header-2" src="@/assets/img/landing-pages/hidden/h_header-2.png">
        <div class="container app-focus">
          <div class="row app-container gap-05" @click="openAppRoute">
            <img src="@/assets/img/landing-pages/hidden/h_app-icon.png">
            <div class="col">
              <strong>Hidden: AR Postcards</strong>
              <span class="note">available on iOS and Android</span>
            </div>
          </div>
          <button class="red full" @click="openAppRoute">get the app</button>
          <p class="note dark-grey centered faded">*You will be redirected to our iOS App Store or Google Play Store listing depending on your device.</p>
        </div>
      </section>
      <section id="hidden-how-it-works">
        <div class="col gap-3 container-large">
          <div>
            <h2>How it works</h2>
            <p>Once you’ve downloaded the app, follow these simple steps to reveal the magic within your postcard! </p>
          </div>
          <div class="row step">
            <div class="step-icon col">
              <img src="@/assets/img/landing-pages/hidden/h_icon_scan.png">
              <span>scan</span>
            </div>
            <div class="col step-description">
              <img src="@/assets/img/landing-pages/hidden/h_tutorial_step-1.png">
              <p>Open the app and scan the artwork on the front of your postcard. You can place the postcard on a flat surface for a better viewing angle.</p>
              <p class="note normal-size">If the digital content shifts around, make sure that the card is well lit, without any strong shadows casting on it. If holding the postcard, make sure that your fingers are not covering large sections of the card. </p>
            </div>
          </div>
          <div class="row step">
            <div class="step-icon col">
              <img src="@/assets/img/landing-pages/hidden/h_icon_watch.png">
              <span>watch</span>
            </div>
            <div class="col step-description">
              <img src="@/assets/img/landing-pages/hidden/h_tutorial_step-2.png">
              <p>Watch the card come to life in Augmented Reality, revealing the world hidden inside the artwork!</p>
              <p class="note normal-size">Make sure you put the sound on too, all of our cards’ digital content have audio!</p>
            </div>
          </div>
          <div class="row step">
            <div class="step-icon col">
              <img src="@/assets/img/landing-pages/hidden/h_icon_interact.png">
              <span class="smaller">interact</span>
            </div>
            <div class="col step-description">
              <img src="@/assets/img/landing-pages/hidden/h_tutorial_step-3.png">
              <p>Follow the instructions on the app - they will guide you on how to interact with the content and help the story progress!</p>
              <p class="note normal-size">Each card has a different interaction. You can learn more about each card below. </p>
            </div>
          </div>
        </div>
      </section>
      <section id="hidden-share-the-magic">
        <div class="bg-red">
          <div class="container-large">
            <img class="postcard" src="@/assets/img/landing-pages/hidden/h_postcard-back.png">
            <div class="additional-margin">
              <p>Our postcards can be sent through<br> standard mail!</p>
              <h3>Share the magic hidden in your <img class="inline-eyes" src="@/assets/img/landing-pages/hidden/h_hidden-eyes.png"> postcard with someone else! </h3>
            </div>
          </div>
        </div>
      </section>
      <section id="hidden-collection">
        <div class="container-large">
          <div>
            <img class="icon" src="@/assets/img/landing-pages/hidden/h_icon_postcard.png">
            <h2>Unique artwork & unique interactions</h2>
          </div>
          <div class="row mb-1">
            <button :class="collectionSelected === collection.season ? 'small-text filter active' : 'small-text filter'" v-for="collection in collections" @click="filterCollection(collection.season)">{{ collection.name }}</button>
          </div>
          <div class="postcard-container">
             <div class="postcard-info-container" v-for="(postcard, index) in postcards" @click="openPostcardDetails(index)">
               <div class="postcard-info" v-if="collectionSelected === postcard.season || collectionSelected === 0">
                 <img :src="require('@/assets/img/landing-pages/hidden/' + postcard.thumbnail)">
                  <h4 class="handwritten"> {{ postcard.name }}</h4>
                  <p>{{ postcard.shortDescription }}</p>
                  <a class="small-text filter">Learn more</a>
               </div>
            </div>
          </div>
          <button class="red full" @click="openPostcards">shop postcards</button>
        </div>
      </section>
      <section id="collaboration">
        <div class="container-large">
          <img src="@/assets/img/landing-pages/hidden/h_icon_artists.png">
          <h2>Artists, we want to bring your artwork to life!</h2>
          <p>We want to expand our postcard collection by collaborating with local artists.<br> If you are interested in turning your artwork into animated postcards, get in touch with us!</p>
          <button class="hidden-dark full mt-2" @click="openMailto">get in touch</button>
        </div>
      </section>
      <footer>
        <div class="container-large">
          <p>The Hidden app and related postcards are designed and developed by Red + Blue Stories.</p>
          <div class="row space-between">
            <img src="@/assets/img/logo_small.png">
            <div class="links col">
              <router-link :to="{ path: '/shop', query: { filter: 'hidden-postcards'} }">shop</router-link>
              <router-link :to="{ path: '/home', hash: '#about' }">about</router-link>
              <router-link :to="{ path: '/privacy-policy', query: { policy: 'hidden-ar-postcards'} }">privacy policy</router-link>
            </div>
          </div>
          <p class="small-text">2024. All rights reserved. Red + Blue Stories Inc.</p>
        </div>
      </footer>
    </div>
</template>

<script>

import router from "@/router/index.js";

export default {
  name: 'HiddenLP',
  components: {},
  data() {
    return {
      isMobile: false,
      redirect: {
        ios: {
          path: 'https://apps.apple.com/app/tales-from-the-trails/id6466105381',
          status: true
        },
        android: {
          path: 'https://play.google.com/store/apps/details?id=com.redbluestories.TalesFromTheTrails',
          status: true
        }
      },
      collectionSelected: 0,
      postcardOpenForDetailsIndex: 1,
    }
  },
  computed: {
    collections() {
      return this.$store.getters.getHiddenCollections;
    },
    postcards() {
      return this.$store.getters.getHiddenPostcards;
    },
    postcardDetails() {
      return this.postcards[this.postcardOpenForDetailsIndex];
    }
  },
  methods: {

    filterCollection(newIndex) {
      if (this.collectionSelected === newIndex) {
        this.collectionSelected = 0;
      } else {
        this.collectionSelected = newIndex;
      }
    },
    openPostcards() {
      router.push({ path: '/shop', query: { filter: 'hidden-postcards'}});
    },
    openAppRoute() {
      router.push({ path: '/hidden-app-redirect'});
    },
    openMailto() {
      window.location = "mailto:hello@redbluestories.com?subject=Hidden Postcard Artist Collaboration";
    },
    openPostcardDetails(index) {
      router.push({name: 'postcardDetails' , params: { cardSlug:  this.postcards[index].slug } });
    },
  },
  mounted() {
    document.body.style.backgroundColor = "#EFEFEF";
  },
  unmounted() {
    document.body.style.backgroundColor = "#8BCFCF";
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap');
@import "@/assets/styles/variables.scss";

.hidden-lp {
  background-color: $hidden-light-grey;
  font-family: 'Poppins', sans-serif;
  overflow-y: hidden;

  #landing-page-nav {
    position: absolute;
    padding: 1.5em;
    right: 0;
    a {
      font-size: .8em;
    }
  }

  button {
    font-size: 1.2em;
    font-weight: 800;
    cursor: pointer;
  }

  button.filter {
    padding: 0.8em 1.2em;
    font-weight: 600;
    font-size: 0.9em;
    margin: 0.2em;

    &:hover {
      background-color: $hidden-mid-red;
      color: $hidden-light-grey;
    }

    &:active, &.active {
      background-color: $hidden-red;
      color: $hidden-light-grey;
    }
  }

  button.red {
  background-color: $hidden-red;
  color: $hidden-light-grey;

    &.outline {
      background-color: $hidden-light-grey;
      color: $hidden-red;
      border: 1px solid $hidden-red;
      font-size: 1em;
      font-weight: 600;
      margin: 0;
    }
  }

  h4.handwritten {
      font-family: "Patrick Hand SC", cursive;
      text-transform: uppercase;
      font-size: 2em;
      font-weight: 400;
    }

  #hidden-lp-header {
    min-height: 90dvh;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    #header-1, #header-2 {
      max-height: 30vh;
      margin: auto;
      display: block;
    }

    button {
       margin: .6rem 0;
    }

    .app-focus {
      margin: -5em 1em 0 1em;

      .note {
        font-size: 0.8em;
        font-weight: 200;
        line-height: 100%;
        font-style: normal;
      }
    }
  }

  #hidden-logo {
    max-width: 70vw;

    h1 {
      font-weight: 700;
      font-size: 14px;
      margin-top: -0.5rem;
    }
  }

  .app-container {
    max-width: 60vw;
    margin: auto;
    background-color: $hidden-light-grey-2;
    border-radius: 20px;
    padding: 0.6em;

    img {
      width: 50px;
      border-radius: 10px;
    }

    strong {
      font-weight: 800;
    }

    .col {
      justify-content: center;
    }
  }

  #hidden-how-it-works {
    background-color: $hidden-dark-grey;
    padding-top: 4em;
    color: $hidden-light-grey;
    padding-bottom: 12em;

    .step {
      justify-content: space-between;
      font-weight: 400;
    }

    .step-icon {
      max-width: 15%;
      span {
        margin-top: 0.4em;
        text-align: center;
        font-weight: 800;
        font-size: 1.1em;

        &.smaller {
          font-size: 1em;
        }
      }
    }
    .step-description {
      max-width: 80%;

      img {
        border-radius: 10px;
        margin-bottom: 1em;
      }
    }
  }

  #hidden-share-the-magic {
    position: relative;

    img.postcard {
      position: absolute;
      top: -10em;
      max-width: 90%;
      margin: 0 5%;
    }
    .bg-red {
      background-color: $hidden-red;
      color: $hidden-light-red;
    }

    .container-large {
      padding-top: 12em;
      padding-bottom: 3em;
    }

    .additional-margin {
      margin: 0 1.1em;
    }

    .inline-eyes {
      display: inline-block;
      width: 35px;
      margin: 0 4px;
    }
  }

  #hidden-collection {
    padding: 3em 0;

    .icon {
      max-width: 70px;
      margin-bottom: 1em;
    }
  }

  #collaboration {
    background-color: $hidden-mid-red;
    padding: 8em 0 3em 0;
    //color: $hidden-light-grey;
    position: relative;
    overflow: hidden;

    h2 {
      width: 65%;
    }

    img {
      position: absolute;
      max-width: 50vw;
      top: -150px;
      right: -80px;
    }
  }

  .postcard-info-container {
    flex: 0 0 auto;
    margin-bottom: 1em;
    cursor: pointer;
  }

  .postcard-info {
    background-color: $hidden-light-grey-2;
    border-radius: 20px;
    color: $hidden-red;
    padding: 2em;
    width: 65vw;
    display: flex;
    flex-direction: column;

    a {
      color: $hidden-red;
      font-weight: 700;
      padding-top: 1em;
    }
  }

  .postcard-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
     gap: 1em;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  footer {
    background-color: #8BCFCF;
    color: #2E4F9C;
    padding: 2em 0 1em 0;

    img {
      width: 60px;
    }

    .links {
      justify-content: flex-end;
      align-items: flex-end;
      a {
        text-transform: uppercase;
        font-size: 1em;
      }
    }
    .space-between {
      margin: 1em 0;
    }
  }
}

.hidden-red {
  color: $hidden-red;
}

.hidden-dark-grey {
  color: $hidden-dark-grey;
}

.mg-auto {
  margin: auto;
}

.faded {
  opacity: 0.55;
}

button.hidden-dark {
  background-color: $hidden-dark-grey;
  color: $hidden-mid-red;
}

.note.normal-size {
  font-size: 1em;
}

.space-between {
  justify-content: space-between;
}

.visible {
  display: block !important;
}

.hidden {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

</style>